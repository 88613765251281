import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Tags from "../components/tags"
import _ from "lodash"
import { Helmet } from "react-helmet"


export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
        <Helmet title="Jessa Sparks | Blog" />
        <div className="row">
          <div className="col-sm">
            <h2>Blog</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10">  
            { 
              posts.filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => {
                  return (
                    <div key={post.id} className="card my-3">
                      <div className="card-body">
                          <h3 className="card-title">
                              <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                          </h3>
                          <p><small>{post.frontmatter.date}</small></p>
                          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                          
                          <p>Tags: {post.frontmatter.tags.map((tag, index) => {
                              return (
                                <span key={index}>
                                    <Link className="tag" to={`/tags/${_.kebabCase(tag)}/`}> {tag}</Link>
                                    { index !== post.frontmatter.tags.length - 1 ? ',' : '' }
                                </span>
                              )
                            })
                          }</p>
                      </div>
                    </div>
                  )
                }
              )
            }
          </div>
          <div className="col-sm-2">
              <Tags/>
          </div>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          html
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`